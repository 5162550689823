import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-chesapeake-virginia.png'
import image0 from "../../images/cities/scale-model-of-chesapeake-parks,-recreation-and-tourism-in-chesapeake-virginia.png"
import image1 from "../../images/cities/scale-model-of-chesapeake-city-park-in-chesapeake-virginia.png"
import image2 from "../../images/cities/scale-model-of-fun-forest-in-chesapeake-virginia.png"
import image3 from "../../images/cities/scale-model-of-chesapeake-arboretum-in-chesapeake-virginia.png"
import image4 from "../../images/cities/scale-model-of-deep-creek-lock-park-in-chesapeake-virginia.png"
import image5 from "../../images/cities/scale-model-of-great-bridge-lock-park-in-chesapeake-virginia.png"
import image6 from "../../images/cities/scale-model-of-centerville-park-in-chesapeake-virginia.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Chesapeake'
            state='Virginia'
            image={image}
            lat='36.7682088'
            lon='-76.28749270000003'
            attractions={ [{"name": "Chesapeake Parks, Recreation and Tourism", "vicinity": "1224 Progressive Dr, Chesapeake", "types": ["park", "point_of_interest", "establishment"], "lat": 36.767249, "lng": -76.23098349999998}, {"name": "Chesapeake City Park", "vicinity": "900 City Park Dr, Chesapeake", "types": ["park", "point_of_interest", "establishment"], "lat": 36.7511871, "lng": -76.22542909999999}, {"name": "Fun Forest", "vicinity": "900 City Park Dr, Chesapeake", "types": ["park", "point_of_interest", "establishment"], "lat": 36.7474672, "lng": -76.2266032}, {"name": "Chesapeake Arboretum", "vicinity": "624 Oak Grove Rd, Chesapeake", "types": ["park", "point_of_interest", "establishment"], "lat": 36.743345, "lng": -76.23923000000002}, {"name": "Deep Creek Lock Park", "vicinity": "300 Luray St, Chesapeake", "types": ["park", "point_of_interest", "establishment"], "lat": 36.7460485, "lng": -76.34151150000002}, {"name": "Great Bridge Lock Park", "vicinity": "100 Locks Rd, Chesapeake", "types": ["park", "point_of_interest", "establishment"], "lat": 36.7241823, "lng": -76.24775420000003}, {"name": "Centerville Park", "vicinity": "1857 Centerville Turnpike S, Chesapeake", "types": ["park", "point_of_interest", "establishment"], "lat": 36.6605919, "lng": -76.1956841}] }
            attractionImages={ {"Chesapeake Parks, Recreation and Tourism":image0,"Chesapeake City Park":image1,"Fun Forest":image2,"Chesapeake Arboretum":image3,"Deep Creek Lock Park":image4,"Great Bridge Lock Park":image5,"Centerville Park":image6,} }
       />);
  }
}